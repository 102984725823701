"use client";
import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {black, Transition} from "@/styles/globalStyleVars";
import Title from "@/components/Title";
import reactHtmlParser from "react-html-parser";
import Link from "next/link";
import {CURSOR_COLOR, CURSOR_EXCLUSION, CURSOR_HIDE, CURSOR_SHOW, CURSOR_TEXT, DispatchCursor} from "haspr-cursor";


const MyComponent = ({data,trending, pt}) => {
    const sliderRef = useRef();
    const [offset, setOffset] = useState(90);
    const [progress, setProgress] = useState(0);
    const [activeTitle, setActiveTitle] = useState('');
    const [activeYear, setActiveYear] = useState('');

    const containerRef = useRef(null);
    const historyData = trending?.list?.map((i)=>({

        category: i?.product_data?.design || '',
        brand: i?.product_data?.brand || '',
        finishing: i?.product_data?.finishing || '',
        title: i?.product_data?.title || '',
        image: i?.images?.list?.find(i => i?.thumb === 'on')?.full_path || '',
        subtitle: i?.product_data?.code || '',
        color: i?.product_data?.color || '',
        slug: i?.product_data?.slug || '',
    }));

    useEffect(() => {
        const updateOffset = () => {
            setOffset(document.querySelector('.container').offsetLeft);
        };

        window.addEventListener('load', updateOffset);
        window.addEventListener('resize', updateOffset);
        updateOffset();

        return () => {
            window.removeEventListener('load', updateOffset);
            window.removeEventListener('resize', updateOffset);
        };
    }, []);
    const updateProgressBar = (swiper) => {
        const totalSlides = swiper.slides.length;
        const activeIndex = swiper.realIndex;
        const newProgress = ((activeIndex + 1) / totalSlides) * 100;
        setProgress(newProgress);

        const activeSlide = historyData[activeIndex];
        setActiveTitle(activeSlide.title);
        setActiveYear(activeSlide.year);
    };
        const [isCursorCustom, setIsCursorCustom] = useState(false);

        useEffect(() => {
            const handleMouseEnter = () => setIsCursorCustom(true);
            const handleMouseLeave = () => setIsCursorCustom(false);

            const section = document.querySelector('#customCursorSection');
            section.addEventListener('mouseenter', handleMouseEnter);
            section.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                section.removeEventListener('mouseenter', handleMouseEnter);
                section.removeEventListener('mouseleave', handleMouseLeave);
            };
        }, []);

    const dispatch = DispatchCursor()

    return (
        <StyledComponent offset={offset} pt={pt} className={'pb-200 trending-slider'} onMouseEnter={CURSOR_SHOW} onMouseLeave={CURSOR_HIDE}>
            <Container>
                <div className={'top title'}>
                    <Title text={data?.section_data?.subtitle} margin={'0 0 40px 0'} marginSm={'0 0 20px 0'}/>
                    <div className={'subtitle'}>
                        <p>{reactHtmlParser(data?.section_data?.short_desc)}</p>
                    </div>
                </div>
            </Container>
            <Container onMouseEnter={() => CURSOR_TEXT(dispatch, 'Drag', 'black')} onMouseLeave={() => CURSOR_TEXT(dispatch, 'END')}
                ref={containerRef}
                id="customCursorSection"
                className={`${isCursorCustom ? 'custom-cursor' : ''} slider-container`}
            >
                <Swiper
                    loop={true}
                    slidesPerView={'auto'}
                    spaceBetween={30}
                    speed={1500}
                    slidesPerGroup={1}
                    modules={[Pagination, Navigation, Mousewheel]}
                    navigation={{
                        prevEl: '.slider_prev',
                        nextEl: '.slider_next',
                    }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    freeMode={true} // Enable free mode for drag functionality
                    freeModeMomentum={true} // Enable momentum for smooth drag
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 15,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    }}
                    onSlideChange={(swiper) => updateProgressBar(swiper)} // Handle slide changes
                    ref={sliderRef}
                    className="mySwiper"
                >

                {historyData?.map((item, index) => (
                        <SwiperSlide key={index}>
                            <Link href={`/products/${item?.slug}`} legacyBehavior>
                                <div className={'list'} key={index}>
                                    <div className="slide-content">
                                        <div className="right">
                                            <div className="img">
                                                <img src={item?.image} alt={item?.title}/>
                                            </div>
                                        </div>
                                        <div className={'bottom'}>
                                            <div className={'cat'}>
                                                <div className={'inner'}>
                                                    <span>{item?.category}</span>
                                                    <span>{item?.finishing}</span>
                                                </div>
                                                <p>{item?.brand}</p>
                                            </div>
                                            <h5>{item?.title}</h5>
                                            <div className={'code'}>
                                                <div style={{backgroundColor: item?.color}}></div>
                                                <p>{item?.subtitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
            <Container>
                <div className={'navigation'}>
                    <div className="progress-bar-container">
                        <div className="progress-bar" style={{width: `${progress}%`}}></div>
                    </div>
                    <div className="top">
                        <div className="top__right">
                            <ul>
                                <li className={'slider_prev'} onMouseEnter={() => CURSOR_EXCLUSION(dispatch, 'START', 'MEDIUM')}
                                    onMouseLeave={() => CURSOR_EXCLUSION(dispatch, 'END')}>
                                    <svg data-magnetism width="12" height="22" viewBox="0 0 12 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1L0.999999 11L11 21" stroke="#534D40" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </li>
                                <li onMouseEnter={() => CURSOR_EXCLUSION(dispatch, 'START', 'MEDIUM')}
                                    onMouseLeave={() => CURSOR_EXCLUSION(dispatch, 'END')} className={'slider_next'}>
                                    <svg data-magnetism width="12" height="22" viewBox="0 0 12 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 21L11 11L1 1" stroke="#534D40" stroke-width="2" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-color: #ffffff;
  padding-top: 300px;
  
  
  .navigation{
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 70px;
    .progress-bar-container {
      width: 100%;
      height: 2px;
      background-color: #BDB8B4;
      position: relative;
    }

    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: #534D40;
      transition-delay: 0.2s;
      transition: width 0.7s ease-in-out;
      overflow: visible;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    .subtitle {
      display: flex;
      justify-content: flex-end;

      p {
        letter-spacing: -0.32px;
        color: #534D40;
        width: 60%;
        opacity: 0.8;
      }
    }

    h2 {
      letter-spacing: -1.8px;
    }

    &__right {
      ul {
        display: flex;

        li {
          position: relative;
          overflow: hidden;
          display: flex;
          height: 40px;
          width: 40px;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          svg{
            height: 20px;
            width: 10px;
          }

          //&.slider_prev{
          //  transform: translateX(0);
          //  transition: transform 0.5s ease;
          //  &:hover {
          //    transform: translateX(-10px);
          //    transition: transform 0.5s ease;
          //  }
          //}
          //&.slider_next{
          //  transform: translateX(0);
          //  transition: transform 0.5s ease;
          //  &:hover {
          //    transform: translateX(10px);
          //    transition: transform 0.5s ease;
          //  }
          //}
        }
      }
    }
  }
.slider-container{
  position: relative;
  z-index: 10000000;
}
  .slide-content {
    .bottom {
      margin-top: 20px;

      .cat {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          color: #959185;
        }

        p {
          color: ${black};
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
        }
      }

      .code {
        display: flex;
        gap: 10px;
        align-items: center;

        p {
          color: ${black};
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }

        div {
          height: 18px;
          width: 18px;
          background: #AF966D;
          border-radius: 50px;
        }
      }

      h5 {
        color: ${black};
        margin-bottom: 10px;
        letter-spacing: -0.72px;
      }
    }
    .right{
      overflow: hidden;
      img{
        transform: scale(1);
        transition: 0.5s ${Transition} transform;
        overflow: hidden;
        &:hover{
          transform: scale(1.1);
          transition: 0.5s ${Transition} transform;
        }
      }
    }
  }
  //.custom-cursor {
  //  cursor: url("/images/static/drag.svg"), auto; /* Fallback to default cursor */
  //}

  
  @media(max-width: 767px){
    padding-top: 200px;
    .swiper{
      padding-right: 70px;
      .swiper-wrapper{
        margin-left: 15px;
      }
    }
    .slider-container{
      padding: 0;
    }
    .top{
      flex-direction: column;
      &.title{
        margin-bottom: 30px;
      }
      .subtitle{
        justify-content: flex-start;
        p{
          width: 100%;
        }
      }
      &__right{
        ul{
          .slider_prev{
            &:hover {
              transform: translateX(0)!important;
            }
          }
          .slider_next{
            &:hover {
              transform: translateX(0)!important;
            }
          }
        }
      }
    }
    .slide-content{
      .bottom{
        h5{
          letter-spacing: -0.6px;
        }
      }
    }
    .navigation{
      margin-top: 30px;
      gap: 20px;
    }
  }



`;

export default MyComponent;
